export const custom_options = {
    due_date: "due_date",
    ticket_outlets: 'ticket_outlets',
    on_sale_date_time_text: 'on_sale_date_time_text',
    include_logo_list: 'include_logos_list',
    send_for_approval: {
        code: 'send',
        sku: {
            send_for_approval: 'send_for_approval'
        }
    },
    send_for_approval_to: 'send_for_approval_to',
    social_set: {
        code: 'social_set',
        sku: {
            ss_package: 'ss_package'
        }
    },
    social_banner_alacarte: {
        code: 'social_banner_a_la_carte',
        sku: {
            sb_alacarte: 'sb_alacarte'
        }
    },
    social_banner_alacarte_qty: 'sb_alacarte_qty',
    social_banner_alacarte_on_sale_info: {
        code: 'social_banner_alacarte_on_sale_info',
        sku: {
            presale: 'presale',
            on_sale_now: "on_sale_now",
            both: 'both'
        }
    },
    facebook: {
        code: 'facebook',
        sku: {
            cover_photo: 'cover_photo_event_header',
            new_feed_image: 'new_feed_image',
            post_engagement: 'post_engagement',
            event_header: 'event_header'
        }
    },
    twitter: {
        code: 'twitter',
        sku: {
            website_card: 'website_card',
            single_image: 'single_image',
            header_photo: 'header_photo'
        }
    },
    instagram: {
        code: 'instagram',
        sku: {
            square: 'square',
            vertical_story: 'vertical_story',
            portrait: 'portrait'
        }
    },
    snapchat:{
        code: 'snapchat',
        sku: {
            ad: 'ad',
            filter: 'filter'
        }
    },
    youtube: {
        code: 'youtube',
        sku: {
            header: 'header'
        }
    },
    static_set: {
        code: 'static_set',
        sku: {
            static_set_package: 'static_set_package'
        }
    },
    static_banner_on_sale_info: {
        code: 'static_banner_on_sale_info',
        sku: {
            presale: 'presale',
            on_sale_now: "on_sale_now",
            both: 'both'
        }
    },
    static_banner_alacarte: {
        code: 'static_banner_a_la_carte',
        sku: {
            sb_alacarte: 'static_banner_alacarte'
        }
    },
    static_banner_alacarte_qty: 'static_banner_alacarte_qty',
    static_banner_alacarte_on_sale_info: {
        code: 'static_banner_alacarte_on_sale_info',
        sku: {
            presale: 'presale',
            on_sale_now: "on_sale_now",
            both: 'both'
        }
    },
    static_banner_alacarte_sizes: {
        code: 'static_banner_alacarte_sizes',
        sku: {
            sb_size_300x600: 'sb_size_300x600',
            sb_size_480x70: 'sb_size_480x70',
            sb_size_728x90: 'sb_size_728x90',
            sb_size_160x600: 'sb_size_160x600',
            sb_size_320x50: 'sb_size_320x50',
            sb_size_970x250: 'sb_size_970x250',
            sb_size_300x250: 'sb_size_300x250',
            sb_size_300x60: 'sb_size_300x60',
        }
    },
    static_banner_alacarte_custom_dimension: 'static_banner_alacarte_custom_dimension',
    static_banner_alacarte_custom_dimension_1: 'static_banner_alacarte_custom_dimension_1',
    static_banner_alacarte_custom_dimension_2: 'static_banner_alacarte_custom_dimension_2',
    static_banner_alacarte_custom_dimension_3: 'static_banner_alacarte_custom_dimension_3',
    static_banner_alacarte_custom_dimension_4: 'static_banner_alacarte_custom_dimension_4',
    static_banner_alacarte_custom_dimension_5: 'static_banner_alacarte_custom_dimension_5',
    static_banner_alacarte_custom_dimension_6: 'static_banner_alacarte_custom_dimension_6',
    static_banner_alacarte_custom_dimension_7: 'static_banner_alacarte_custom_dimension_7',
    static_banner_alacarte_custom_dimension_8: 'static_banner_alacarte_custom_dimension_8',
    static_banner_alacarte_custom_dimension_9: 'static_banner_alacarte_custom_dimension_9',


    static_ecard: {
        code: 'static_ecard',
        sku: {
            static_ecard_package: 'static_ecard_package'
        }
    },
    static_ecard_on_sale_info: {
        code: 'static_ecard_on_sale_info',
        sku: {
            presale: 'presale',
            on_sale_now: "on_sale_now",
            both: 'both'
        }
    },
    static_ecard_required_sizes: 'static_ecard_required_sizes',


    digital_billboard: {
        code: 'digital_billboard',
        sku: {
            digital_billboard_package: 'digital_billboard_package'
        }
    },
    digital_billboard_qty: 'digital_billboard_package_qty',
    digital_billboard_on_sale_info: {
        code: 'digital_billboard_on_sale_info',
        sku: {
            presale: 'presale',
            on_sale_now: "on_sale_now",
            both: 'both'
        }
    },
    digital_billboard_sizes: 'digital_billboard_sizes',
    digital_billboard_special_instructions: 'digital_billboard_special_instructions',


    billboard_template : {
        code: 'billboard_template',
    },
    billboard_template_1 : {
        code: 'billboard_template_1',
    },
    billboard_template_2 : {
        code: 'billboard_template_2',
    },
    billboard_template_3 : {
        code: 'billboard_template_3',
    },
    billboard_template_4 : {
        code: 'billboard_template_4',
    },
    billboard_template_5 : {
        code: 'billboard_template_5',
    },
    billboard_template_6 : {
        code: 'billboard_template_6',
    },
    billboard_template_7 : {
        code: 'billboard_template_7',
    },
    billboard_template_8 : {
        code: 'billboard_template_8',
    },
    billboard_template_9 : {
        code: 'billboard_template_9',
    },
    include_logo_file : {
        code: 'include_logo_file',
    },
    include_logo_file_1 : {
        code: 'include_logo_file_1',
    },
    include_logo_file_2 : {
        code: 'include_logo_file_2',
    },
    include_logo_file_3 : {
        code: 'include_logo_file_3',
    },
    include_logo_file_4 : {
        code: 'include_logo_file_4',
    },
    include_logo_file_5 : {
        code: 'include_logo_file_5',
    },
    include_logo_file_6 : {
        code: 'include_logo_file_6',
    },
    include_logo_file_7 : {
        code: 'include_logo_file_7',
    },
    include_logo_file_8 : {
        code: 'include_logo_file_8',
    },
    include_logo_file_9 : {
        code: 'include_logo_file_9',
    },
};

export const fields = {
    [custom_options.due_date]: '',
    [custom_options.on_sale_date_time_text]: '',
    [custom_options.include_logo_list]: '',
    [custom_options.ticket_outlets]: '',
    [custom_options.send_for_approval_to]: '',
    [custom_options.send_for_approval.code]: {},
    [custom_options.send_for_approval.sku.send_for_approval]: false,

    /**
     * SOCIAL BANNER SECTION
     */
    [custom_options.social_set.code]: {},
    [custom_options.social_set.sku.ss_package]: false,

    [custom_options.social_banner_alacarte.code]: {},
    [custom_options.social_banner_alacarte.sku.sb_alacarte]: false,
    [custom_options.social_banner_alacarte_qty]: 0,

    [custom_options.social_banner_alacarte_on_sale_info.code]: '',
    [custom_options.facebook.code]: {},
    [custom_options.twitter.code]: {},
    [custom_options.instagram.code]: {},
    [custom_options.snapchat.code]: {},
    [custom_options.youtube.code]: {},

    /**
     * STATIC BANNER SECTION
     */
    [custom_options.static_banner_alacarte_sizes.code]: {},
    [custom_options.static_set.code]: {},

    [custom_options.static_banner_alacarte.code]: {},
    [custom_options.static_banner_alacarte.sku.sb_alacarte]: false,
    [custom_options.static_banner_alacarte_qty]: 0,

    [custom_options.static_banner_alacarte_on_sale_info.code]: '',
    [custom_options.static_banner_on_sale_info.code]: '',
    [custom_options.static_banner_alacarte_sizes.code]: {},
    [custom_options.static_banner_alacarte_custom_dimension]: '',
    [custom_options.static_banner_alacarte_custom_dimension_1]: '',
    [custom_options.static_banner_alacarte_custom_dimension_2]: '',
    [custom_options.static_banner_alacarte_custom_dimension_3]: '',
    [custom_options.static_banner_alacarte_custom_dimension_4]: '',
    [custom_options.static_banner_alacarte_custom_dimension_5]: '',
    [custom_options.static_banner_alacarte_custom_dimension_6]: '',
    [custom_options.static_banner_alacarte_custom_dimension_7]: '',
    [custom_options.static_banner_alacarte_custom_dimension_8]: '',
    [custom_options.static_banner_alacarte_custom_dimension_9]: '',

    /**
     * STATIC E-CARD SECTION
     */
    [custom_options.static_ecard.code]: {},
    [custom_options.static_ecard_on_sale_info.code]: '',
    [custom_options.static_ecard_required_sizes]: '',

    /**
     * DIGITAL BILLBOARD SIGNAGE SECTION
     */

    [custom_options.digital_billboard.code]: {},
    [custom_options.digital_billboard_qty]: '',
    [custom_options.digital_billboard_on_sale_info.code]: '',
    [custom_options.digital_billboard_sizes]: '',
    [custom_options.digital_billboard_special_instructions]: '',

    [custom_options.billboard_template.code]: undefined,
    [custom_options.billboard_template_1.code]: undefined,
    [custom_options.billboard_template_2.code]: undefined,
    [custom_options.billboard_template_3.code]: undefined,
    [custom_options.billboard_template_4.code]: undefined,
    [custom_options.billboard_template_5.code]: undefined,
    [custom_options.billboard_template_6.code]: undefined,
    [custom_options.billboard_template_7.code]: undefined,
    [custom_options.billboard_template_8.code]: undefined,
    [custom_options.billboard_template_9.code]: undefined,

    [custom_options.include_logo_file.code]: undefined,
    [custom_options.include_logo_file_1.code]: undefined,
    [custom_options.include_logo_file_2.code]: undefined,
    [custom_options.include_logo_file_3.code]: undefined,
    [custom_options.include_logo_file_4.code]: undefined,
    [custom_options.include_logo_file_5.code]: undefined,
    [custom_options.include_logo_file_6.code]: undefined,
    [custom_options.include_logo_file_7.code]: undefined,
    [custom_options.include_logo_file_8.code]: undefined,
    [custom_options.include_logo_file_9.code]: undefined,
}
