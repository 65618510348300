import React from "react"
import {connect} from "react-redux"
import {cleanEditAddressState, editAddressAttempt} from "./actions";
import {withRouter} from "react-router-dom";
import {_getAvailableRegions} from '../../helpers'
import {deleteAddress} from "../../containers/CustomerInformation/actions";
import {getCountries} from "../Countries/actions";
import ModalConfirmation from "../ModalConfirmation";
import {FIELDS_CODE} from "../../actions/Constants";
import CustomCheckbox from "../CustomCheckbox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const mapStateToProps = state => ({
    ...state.customerInformation,
    ...state.accountEditAddressForm,
    ...state.countriesData
})


const mapDispatchToProps = {
    editAddressAttempt,
    getCountries,
    cleanEditAddressState,
    deleteAddress,
}

class AccountEditAddressForm extends React.Component{
    constructor(props)
    {
        super(props);
        this.fieldsRequired =  ['firstname',
            'lastname',
            'company',
            'telephone',
            'street',
            'city',
            'postcode',
            'region_id',
            'country_id'
        ];
        this.state = {
            address: {
                region: {
                    region_code:"",
                    region:"",
                    region_id:""
                },
                region_id:"",
                country_id:"US",
                street:[],
                telephone:"",
                postcode:"",
                postcode_plus:"",
                city:"",
                firstname:"",
                lastname:"",
                id: null,
                default_billing: false,
                company: '',
                fax: ''
            },
            availableRegions: null,
            errors: [],
            errorMessages:{},
            hideDialog: true
        }

        this.updateAddress = this.updateAddress.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.deleteAddress = this.deleteAddress.bind(this);
        this.showDeleteAddressDialog = this.showDeleteAddressDialog.bind(this);
        this.hideDeleteAddressDialog = this.hideDeleteAddressDialog.bind(this);
    }

    componentDidMount() {
        if(!this.props.countries) {
            this.props.getCountries();
        }
        else {
            let availableRegions = _getAvailableRegions('US', this.props.countries);
            this.setState({
                availableRegions: availableRegions
            })
        }
        let {customerInfo, match} = this.props;
        if(customerInfo  && match != "new"){
            this._prepareAddressData(customerInfo, match.params.addressId);
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        let {customerInfo, match} = this.props;
        if(customerInfo && prevProps.match != match) {
            this._prepareAddressData(customerInfo, match.params.addressId);
        }
        if(customerInfo && prevProps.customerInfo != customerInfo && match != "new") {
            this._prepareAddressData(customerInfo, match.params.addressId);
        }
        let country_id = this.state.address.country_id;
        let countries = this.props.countries;
        let availableRegions = null;
        if(prevState.address.country_id != country_id) {
            availableRegions = _getAvailableRegions(country_id, countries);
            this.setState({
                availableRegions: availableRegions
            })
        }
        if(prevProps.countries == null && countries) {
            availableRegions = _getAvailableRegions(country_id, countries);
            this.setState({
                availableRegions: availableRegions
            })
        }
        if(prevProps.removeAddressData != this.props.removeAddressData && this.props.removeAddressData) {
            this.props.history.goBack();
        }
        if(prevProps.countries != this.props.countries) {
            let availableRegions = _getAvailableRegions('US', this.props.countries);
            this.setState({
                availableRegions: availableRegions
            })
        }
    }

    componentWillUnmount() {
        this.props.cleanEditAddressState();
    }

    _prepareAddressData(customerInfo, addressId) {
        customerInfo.addresses.map((address) => {
            if(address.id.toString() == addressId)
            {
                let addressTemp = {...address};
                addressTemp['default_billing'] = address.id == customerInfo.default_billing?true: false;
                addressTemp['company'] = addressTemp['company']? addressTemp['company']: '';
                addressTemp['fax'] = addressTemp['fax']? addressTemp['fax']: '';
                this.setState({
                    address: addressTemp
                })
            }
        })
    }
    updateAddress(event){
        event.preventDefault();
        let customerId = this.props.customerInfo.id;
        if(this.formIsValid()) {
            let address = {...this.state.address};
            if(address.country_id === 'US') {
                address.postcode = `${address.postcode}-${address.postcode_plus}`;
            }
            delete address['postcode_plus'];
            this.props.editAddressAttempt(customerId, address);
        }

    }

    handleChange(event){
        const { name, value } = event.target;
        let address = this.state.address;
        let availableRegions = this.state.availableRegions;
        let countries = this.props.countries;
        switch (name) {
            case 'street_1':
                address['street'][0] = value;
                break;
            case 'street_2':
                address['street'][1] = value;
                break;
            case 'default_billing':
                address.default_billing = !address.default_billing;
                break;
            case 'country_id':
                let option = event.target.options[event.target.selectedIndex];
                let countryIndex = option.getAttribute('index');
                if(countryIndex) {
                    availableRegions = countries[countryIndex]['available_regions'];
                    address.country_id = value;
                    address.region_id = null;
                    address.region = {};
                }
                else {
                    address.country_id = '';
                    address.region_id = '';
                    address.region = {};
                    availableRegions = [];
                }
                console.log('address', address);
                break;
            case "region":
                if(availableRegions && availableRegions.length > 0) {
                    let regionOption = event.target.options[event.target.selectedIndex];
                    let regionIndex = regionOption.getAttribute('index');
                    let region = availableRegions[regionIndex];
                    if (region) {
                        address.region.region = region.name;
                        address.region.region_code = region.code;
                        address.region.region_id = region.id;
                        address.region_id = region.id;
                    } else {
                        address.region = {};
                        address.region_id = '';
                    }
                } else {
                    address.region = {};
                    address.region_id = 0;
                    address.region.region = value;
                    address.region.region_id = 0;
                }
                break;
            default:
                address[name] = value;
        }

        this.setState({
            address: address,
            availableRegions: availableRegions,
        });
    }
    formIsValid() {
        let errors = [];
        let {address, availableRegions} =  {...this.state};
        let errorMessages = {};

        for(let rIndex in this.fieldsRequired) {
            let fieldValue = this.fieldsRequired[rIndex];
            if(fieldValue === 'street') {
                if(address['street'].length === 0 || address['street'][0] === '') {
                    errors.push(fieldValue);
                    continue;
                }
            }
            if(fieldValue === 'region_id') {
                console.log('availableRegions', availableRegions)
                if(address.region_id === "" || address.region_id === null) {
                    errors.push('region_id');
                }
                continue;
            }
            if(Array.isArray(address[fieldValue]) && address[fieldValue].length === 0 ) {
                errors.push(fieldValue);
            }
            if(!address[fieldValue] || (typeof address[fieldValue] == "string" && address[fieldValue] === "")) {
                errors.push(fieldValue)
            }
        }

        if(address['country_id'] === 'US') {
            let zipcodeRegex = /^([0-9]{5})$/;
            if(!zipcodeRegex.test(address['postcode'])) {
                errors.push('postcode');
            }
            let zipcodePlusRegex = /^([0-9]{4})$/;
            if(!zipcodePlusRegex.test(address['postcode_plus'])) {
                errors.push('postcode_plus');
                errorMessages['postcode_plus'] = 'Zip+4 must be four digits.';
            }
        }

        this.setState({
            errors: errors,
            errorMessages: errorMessages
        });
        if(errors.length === 0) {
            return  true;
        }
        else {
            window.scrollTo({top: 0, behavior: "smooth", block: 'start'})
            return false;
        }
    }

    deleteAddress() {
        let address = this.state.address;
        if(address) {
            this.props.deleteAddress(address.id, this.props.customerInfo)
        }
    }
    showDeleteAddressDialog() {
        this.setState({hideDialog: false});
    }
    hideDeleteAddressDialog() {
        this.setState({hideDialog: true});
    }
    render(){
        let {address, availableRegions, errors, hideDialog, errorMessages} = this.state;
        let {countries, updateAddressError, isUpdatingAddress, customerInfo, editAddressSuccess} = this.props;
        let param = this.props.match.params.addressId;
        if(!errors) {
            errors = [];
        }
        if(isUpdatingAddress) {
            return (
                <div>
                    <div className="loading-white">
                        <i className="icon-loading"></i>
                        <span>Updating Address</span>
                    </div>
                </div>
            )
        }
        console.log('address111', address)
        if(address){
            return (
                <div>
                    <form onSubmit={this.updateAddress}>
                        <section className="row section-account">
                            <div className="col-md-8">
                                {errors && errors.length > 0 && <div className="alert error">
                                    <p>Please complete the fields outlined in red below before proceeding.</p>
                                    {errorMessages.postcode_plus && <p>{errorMessages.postcode_plus}</p>}
                                </div>}
                                {updateAddressError && <div className="alert error">
                                    <p>{updateAddressError.message ?updateAddressError.message:updateAddressError}</p>
                                </div>}
                                {editAddressSuccess && <div className="alert success">
                                    <p>Your addresses was updated successfully.</p>
                                </div>}
                                <small className="required">Required</small>
                                <div className="row">
                                    <div className="form-group col-sm-6">
                                        <label className="required">First Name</label>
                                        <input type="text" name="firstname"
                                            value={address.firstname}
                                            onChange={this.handleChange}
                                            className={errors.indexOf('firstname') != -1?'error':""}
                                        />
                                    </div>
                                    <div className="form-group col-sm-6">
                                        <label className="required">Last Name</label>
                                        <input type="text" name="lastname"
                                               required
                                               value={address.lastname}
                                                onChange={this.handleChange}
                                               className={errors.indexOf('lastname') != -1?'error':""}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-xs-12">
                                        <label className="required">Company</label>
                                        <input type="text"
                                               value={address.company}
                                               onChange={this.handleChange}
                                               name="company"
                                               className={errors.indexOf('company') != -1?'error':""}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-sm-6">
                                        <label className="required">Telephone</label>
                                        <input type="text" name="telephone"
                                               value={address.telephone}
                                               onChange={this.handleChange}
                                               className={errors.indexOf('telephone') != -1?'error':""}
                                        />
                                    </div>
                                    <div className="form-group col-sm-6">
                                        <label>Fax</label>
                                        <input type="text"
                                               name="fax"
                                               value={address.fax}
                                               onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-sm-8">
                                        <label className="required">Street Address</label>
                                        <input type="text" name="street_1"
                                               value={address.street[0]? address.street[0]: ''}
                                               onChange={this.handleChange}
                                               className={errors.indexOf('street') != -1?'error':""}
                                        />
                                    </div>
                                    <div className="form-group col-sm-4">
                                        <label>Suite, building, floor, etc.</label>
                                        <input type="text" name="street_2"
                                               value={address.street[1]? address.street[1]: ''}
                                               onChange={this.handleChange}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-sm-6">
                                        <label className="required">City</label>
                                        <input type="text" name="city"
                                               value={address.city}
                                               onChange={this.handleChange}
                                               className={errors.indexOf('city') != -1?'error':""}
                                        />
                                    </div>
                                    <div className="form-group col-sm-6">
                                        <label className="required">State / Province</label>
                                        {(!availableRegions || Object.keys(availableRegions).length === 0)?
                                          <input
                                            type={'text'}
                                            name='region'
                                            value={(address.region && address.region.region)?address.region.region:''}
                                            placeholder={'State / Province'}
                                            onChange={this.handleChange}
                                            index={-1}
                                            className={errors.indexOf('region_id') != -1?'error':""}
                                          />
                                          :(<select name='region'
                                                   onChange={this.handleChange}
                                                   value={address.region && address.region.region_code?address.region.region_code:''}
                                                   className={errors.indexOf('region_id') != -1?'error':""}
                                          >
                                              <option value={''}>Select Region, State, or Province</option>
                                              {availableRegions &&
                                                Object.keys(availableRegions).map((regionIndex) =>
                                                  <option id={availableRegions[regionIndex].id}
                                                          value={availableRegions[regionIndex].code}
                                                          key={regionIndex}
                                                          index={regionIndex}
                                                  >
                                                      {availableRegions[regionIndex].name}
                                                  </option>
                                                )
                                              }
                                          </select>)
                                        }

                                    </div>
                                </div>
                                <div className="row">
                                    <div className={address.country_id === 'US' ? "form-group col-sm-3":"form-group col-sm-6"}>
                                        <label className="required">Zip / Postal Code</label>
                                        <input type="text" name="postcode"
                                               value={address.postcode}
                                               onChange={this.handleChange}
                                               className={errors.indexOf('postcode') != -1?'error':""}
                                        />
                                    </div>
                                    {address.country_id === 'US' && <div className="form-group col-sm-3">
                                        <label className="required">
                                            Zip+4
                                        </label>
                                        <a href="https://tools.usps.com/zip-code-lookup.htm"
                                           title="Unsure what your zip+4 is? Click here to look it up."
                                           style={{display: ''}}
                                           target="_blank"
                                        >
                                            <FontAwesomeIcon
                                              icon={{name: 'circle-question', style: 'solid'}}
                                              style={{
                                                  color: 'black',
                                                  marginLeft: '5px'
                                              }}
                                            />
                                        </a>
                                        <input type="text" name="postcode_plus"
                                               value={address.postcode_plus}
                                               onChange={this.handleChange}
                                               className={errors.indexOf('postcode_plus') != -1?'error':""}
                                        />
                                    </div>}
                                    <div className="form-group col-sm-6">
                                        <label className="required">Country</label>
                                        <select onChange={this.handleChange}
                                                value={address.country_id}
                                                name="country_id"
                                                className={errors.indexOf('country_id') != -1?'error':""}
                                        >
                                            <option >Select Country</option>
                                            {countries && Object.keys(countries).map((index) =>
                                                <option id={countries[index].id} value={countries[index].id}
                                                        key={index} index={index}>
                                                    {countries[index].full_name_english}
                                                </option>
                                            )}

                                        </select>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <div className="form-options">
                            <ModalConfirmation
                                onConfirm={this.deleteAddress}
                                onCancel={this.hideDeleteAddressDialog}
                                hidden={hideDialog}
                                type={'large'}
                            />
                            {hideDialog && <div className="row">
                                <div className="col-md-3 col-sm-4 col-xs-12">
                                    <button type="reset" onClick={this.props.history.goBack} className="button medium grey">
                                        Cancel
                                    </button>
                                </div>
                                <div className="col-md-3 col-md-offset-3 col-sm-4 col-sm-offset-4 col-xs-12">
                                    {customerInfo &&  param !== 'new' && param != customerInfo.default_billing &&
                                    <button type="button" className="button medium red" onClick={this.showDeleteAddressDialog}>
                                        Delete
                                    </button>}
                                </div>
                                <div className="col-md-3 col-sm-4 col-xs-12">
                                    <button type="button" onClick={this.updateAddress} className="button medium blue">Save</button>
                                </div>
                            </div>}
                        </div>
                    </form>
                </div>
            )
        }
        else{
            return (
                <div>
                    No address
                </div>
            )
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountEditAddressForm))
