import React from "react"
import {connect} from "react-redux"
import {withRouter} from "react-router-dom";
import {cleanEditCartItem, getProductBySku} from "./actions";
import {
    getCategoryCustomOption,
    splitItemCartIntoOrderProcessInformation,
    checkIfProductHasSharedFields,
    getCutFromSelectedProducts, getAllCutsFromSelectedProducts, getFieldsToShowOnVenueSection
} from "../../helpers";
import OrderFormStep2 from "../OrderFormStep2";
import {setProductsToCart, setSelectedProducts} from "../../components/OrderFormStep1ProductList/actions";
import OrderFormStep3 from "../OrderFormStep3";
import {
    setCategoryInfo,
    setProductsLengths,
    setProductsShows,
    setProductsToCartDetails,
    setProductsCustomOptions, setShippingProducts, setCutProducts, orderProcessAllData
} from "../OrderFormStep1/actions";
import {cleanUpOrderProcessState} from "../OrderFormStep1/actions";
import {CATEGORY_IMAGES_SERVICES, CDN_URL, FIELDS_CODE} from "../../actions/Constants";
import LoadingBlack from "../../components/LoadingBlack";

const mapDispatchToProps = {
    getProductBySku,
    setProductsShows,
    setProductsToCartDetails,
    setSelectedProducts,
    setProductsLengths,
    setProductsToCart,
    setCategoryInfo,
    setProductsCustomOptions,
    setShippingProducts,
    cleanUpOrderProcessState,
    cleanEditCartItem,
    setCutProducts,
    orderProcessAllData
};

const mapStateToProps = state => ({
    ...state.shoppingCartEditItem,
    ...state.customerInformation,
    ...state.productsToCart
});

class ShoppingCartEditItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showsFields: null,
            sharedFields: null,
            lengthFields: null,
            customOptions: null,
            productInCart: null,
            shippingInfo: null,
            currentStep: 'step2',
            categoryInfo: {},
            backgroundImage: `${CDN_URL}assets/backgrounds/bg-order.jpg`
        };
        this.updateLength = this.updateLength.bind(this);
        this.updateShow = this.updateShow.bind(this);
        this.updateSharedInformation = this.updateSharedInformation.bind(this);
        this.nextStepUpdate = this.nextStepUpdate.bind(this);
        this.goBackUpdate = this.goBackUpdate.bind(this);
    }

    componentDidMount() {
        console.log("component did mount", this.state, this.props);
        if(this.props.history.location.state) {
            let product = this.props.history.location.state['product'];
            let cartItems = this.props.history.location.state['cartItems'];
            if(product){
                let currentStep = this.state.currentStep;
                let originalProduct = this.props.originalProduct;
                if(
                    originalProduct && originalProduct.extension_attributes &&
                    (originalProduct.extension_attributes.product_type.id == 'shipping' || originalProduct.extension_attributes.product_type.id == 'revision_product')) {
                    console.log('did mount original product', originalProduct)
                    currentStep = 'step3';
                }
                if(getFieldsToShowOnVenueSection([product]).length === 0) {
                    currentStep = 'step3'
                }
                let productSku = product.extension_attributes.product_sku;
                this.props.getProductBySku(productSku);
                let optionsInformation = splitItemCartIntoOrderProcessInformation(product, cartItems);
                let categoryInfo = this.getCategoryInfo();
                console.log('optionsInformation.lengthInformation', 'productSku', productSku,optionsInformation.lengthInformation)
                this.setState({
                    productInCart: product,
                    showsFields: optionsInformation.showInformation,
                    lengthFields: optionsInformation.lengthInformation,
                    sharedFields: optionsInformation.sharedInformation,
                    customOptions: optionsInformation.customOptionsInformation,
                    shippingInfo: optionsInformation.shippingInformation,
                    categoryInfo: categoryInfo,
                    currentStep: currentStep,
                }, () => {this.updateOrderProcess()})
            }
        }
        else {
            this.props.history.push("/cart");
        }

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.productInCart && prevState.productInCart !== this.state.productInCart) {
            console.log("component did update");
            let product = this.state.productInCart;
            let cartItems = this.props.history.location.state['cartItems'];
            let optionsInformation = splitItemCartIntoOrderProcessInformation(product, cartItems);
            let categoryInfo = this.getCategoryInfo();
            this.setState({
                showsFields: optionsInformation.showInformation,
                lengthFields: optionsInformation.lengthInformation,
                sharedFields: optionsInformation.sharedInformation,
                customOptions: optionsInformation.customOptionsInformation,
                categoryInfo: categoryInfo
            })

        }
        console.log('prevstate', prevState);
        console.log('this.state', this.state);

        if(
            prevState.customOptions !== this.state.customOptions
            || prevState.lengthFields !== this.state.lengthFields
            || prevState.productInCart !== this.state.productInCart
            || prevState.sharedFields !== this.state.sharedFields
            || prevState.shippingInfo !== this.state.shippingInfo
            || prevState.showsFields !== this.state.showsFields
            || prevState.categoryInfo !== this.state.categoryInfo
        ){
            this.updateOrderProcess();
        }


        if(prevProps.originalProduct != this.props.originalProduct) {
            let originalProduct = this.props.originalProduct;
            let currentStep = this.state.currentStep;
            if(
                originalProduct && originalProduct.extension_attributes &&
                (originalProduct.extension_attributes.product_type.id === 'shipping' || originalProduct.extension_attributes.product_type.id === 'revision_product')) {
                currentStep = 'step3';
            }
            let productInCart = {...this.state.productInCart};
            productInCart.sku = originalProduct?.sku;
            this.setState({
                productInCart: productInCart,
                currentStep: currentStep
            })
        }
        let categoryInfo = this.state.categoryInfo;
        if(prevState.categoryInfo != this.state.categoryInfo) {
            if(categoryInfo.category_background_image && categoryInfo.category_background_image != ""){
                this.setState({
                    backgroundImage: this.state.categoryInfo.category_background_image
                })
            }
        }
        console.log('After ...', this.state)
    }
    updateOrderProcess() {
        let {
            showsFields,
            lengthFields,
            sharedFields,
            productInCart,
            categoryInfo,
            customOptions,
            shippingInfo} = {...this.state};
        let originalProduct = this.props.originalProduct;


        // this.props.setProductsShows(showsFields);
        this.props.setProductsToCartDetails(sharedFields);
        if(originalProduct){
            let selectedProducts = {[originalProduct.id]: originalProduct}
            if(lengthFields){
                // this.props.setProductsLengths({
                //         [originalProduct.id]: lengthFields
                //     }
                // );
            }
            let productsLength = {
                [originalProduct.id]: lengthFields
            };
            let productsCustomOptions = {
                [originalProduct.sku]: customOptions
            }
            // this.props.setProductsCustomOptions({
            //         [originalProduct.sku]: customOptions
            //     }
            // );
            let productsToCart = [];
            let product = {
                sku: originalProduct.sku,
                qty: productInCart.qty,
                name: originalProduct.name,
                price: originalProduct.price,
                product_type: originalProduct.type_id,
                quote_id: productInCart.quote_id,
                item_id: productInCart.item_id,
                group_item: productInCart.extension_attributes.group_item,
                cart_group_item: productInCart.extension_attributes.cart_group_item
            };
            productsToCart.push(product)
            // this.props.setProductsToCart(productsToCart);

            console.log('shippingInfo', shippingInfo)
            // this.props.setSelectedProducts(selectedProducts);
            let shippingProducts = {[originalProduct.id]: shippingInfo};
/*            if(shippingInfo && shippingInfo.length > 0){
                this.props.setShippingProducts(shippingProducts);
            }*/
            this.setCutProducts(showsFields, selectedProducts);


            this.props.orderProcessAllData(
                {
                    productsToCart: productsToCart,
                    productsToCartShipping: shippingProducts,
                    productsCustomOptions: productsCustomOptions,
                    productsToCartLengths: productsLength,
                    productsToCartShows: showsFields,
                    selectedProducts: selectedProducts

                }
            );
        }
        this.props.setCategoryInfo(categoryInfo);
    }

    setCutProducts(showsFields, selectedProducts) {
        if(showsFields.length > 0) {
            let showField = showsFields[0];
            let cutProducts = getAllCutsFromSelectedProducts(showField[FIELDS_CODE.venue_capacity.code], selectedProducts);
            this.props.setCutProducts(cutProducts);
        }
    }

    updateShow(showIndex=0, showInformation = {}) {
        let showsFields = this.state.showsFields;
        showsFields[showIndex] = showInformation;
        this.setState({
            showsFields: showsFields
        })
    }
    updateLength(lengthIndex, lengthFields)
    {
        this.setState({
            lengthFields: lengthFields
        })
    }
    updateSharedInformation(sharedInformation)
    {
        this.setState({
            sharedFields: {...sharedInformation}
        })
    }
    getCategoryInfo()
    {
        let product = this.state.productInCart;
        let categoryInfo = {};
        console.log('product', product)
        if(product) {
            if(product.product_option) {
                let optionList = product.product_option.extension_attributes.custom_options;
                categoryInfo = getCategoryCustomOption(optionList);
            }
        }
        return categoryInfo;
    }
    nextStepUpdate(data = null){
        let {currentStep} = this.state;
        if (currentStep == 'step2') {
            if(data.shows) {
                console.log('shows', data.shows);
                this.setState({
                    showsFields: data.shows
                })
                this.props.setProductsShows(data.shows);
            }
            if(data.details) {
                this.setState({
                    sharedFields: data.details
                });
                this.props.setProductsToCartDetails(data.details);
            }
            currentStep = 'step3';
        }
        this.setState({
            currentStep: currentStep
        })
    }

    goBackUpdate() {

        let {currentStep} = this.state;
        let originalProduct = this.props.originalProduct;
        if(currentStep == 'step2' || (currentStep == 'step3' && !checkIfProductHasSharedFields(originalProduct))) {
            this.props.history.push('/cart');
        }
        if (currentStep == 'step3') {
            this.setState({
                currentStep: 'step2'
            })
        }
    }
    componentWillUnmount() {
        this.props.cleanUpOrderProcessState();
        this.props.cleanEditCartItem()
    }
    render() {
        let {showsFields, productInCart} = {...this.state};
        let {isFetchingEditCartItem, isFetchingAddProductToCart} = this.props;
        let currentStep = this.state.currentStep;
        let backgroundImage = this.state.backgroundImage;
        let categoryInfo = {
            category_background_image: backgroundImage
        }
        if(this.state.categoryInfo) {
            categoryInfo = this.state.categoryInfo;
        }
        if(isFetchingEditCartItem) {
            return (
                <main className="black" role="main" style={{backgroundImage: `url(${backgroundImage})`, backgroundSize: "auto"}}>
                    <div id="content" className="container fullscreen-checkout">
                        <div id="order-form" className="step-one row">
                            <div className="col-xs-12">
                                <LoadingBlack
                                    label="Loading Order Form"
                                />
                            </div>
                        </div>
                    </div>
                </main>
            )
        }
        if(isFetchingAddProductToCart) {
            return (
                <main className="black" role="main" style={{backgroundImage: `url(${backgroundImage})`, backgroundSize: "auto"}}>
                    <div id="content" className="container fullscreen-checkout">
                        <div id="order-form" className="step-one row">
                            <div className="col-xs-12">
                                <LoadingBlack
                                    label="Updating Cart"
                                />
                            </div>
                        </div>
                    </div>
                </main>
            )
        }

        return (
            <div>
                {currentStep == 'step2' &&
                    <OrderFormStep2
                        nextStep={this.nextStepUpdate}
                        shows={showsFields}
                        goBack={this.goBackUpdate}
                        categoryInfo={categoryInfo}
                    />
                }
                {currentStep == 'step3' &&
                categoryInfo &&
                    <OrderFormStep3
                        nextStep={this.nextStepUpdate}
                        goBack={this.goBackUpdate}
                        itemId={productInCart.item_id}
                        categoryInfo={categoryInfo}
                />}

            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShoppingCartEditItem))
