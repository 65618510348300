import React from "react";
import {connect} from "react-redux";
import OrderFormStep3ProductInfo from "../OrderFormStep3ProductInfo";
import {setOrderProcessTotalPrice, setProductsLengths, setShippingProducts} from "../../containers/OrderFormStep1/actions";
import {withRouter} from 'react-router-dom';
import UpdateCartItemProduct from "../UpdateCartItemButton";
import {getOptionByCode, getTotalPrice, numberFormat, setCutTypeToAllCutLength, validEmailRegex} from "../../helpers";
import {FIELDS_CODE, isPkgProduct, LENGTH_INFORMATION} from "../../actions/Constants";
import OrderFormStep3Shipping from "../OrderFormStep3Shipping";
import LoadingSmall from "../LoadingSmall";


const mapStateToProps = state => ({
    ...state.orderProcess,
    ...state.customerInformation,
    ...state.productsToCart,
    ...state.cart,
    ...state.additionalPkgProducts,
})
const mapDispatchToProps = {
    setProductsLengths,
    setOrderProcessTotalPrice,
    setShippingProducts,
}

class OrderFormStep3General extends React.Component{
    constructor(props) {
        super(props);
        this.timeOut = 0;
        this.state = {
            productsLengths: {},
            categoryInfo: {},
            formErrors: {}
        };

        this.saveLengthsInfo = this.saveLengthsInfo.bind(this);
        this.saveProductInfoState = this.saveProductInfoState.bind(this);
        this.goBack = this.goBack.bind(this);
        this.getTotalPrice = this.getTotalPrice.bind(this);
        this.formValid = this.formValid.bind(this);
        this.getAllCustomOptionErrors = this.getAllCustomOptionErrors.bind(this);
        this.initializeShipping = this.initializeShipping.bind(this);
    }
    componentDidMount() {
        if(this.props.categoryInfo) {
            let categoryInfo = this.props.categoryInfo;
            this.setState({
                categoryInfo: categoryInfo
            })
        }
        if(this.props.selectedProducts && !this.props.productsToCartLengths) {
            let productsLengths = {};
            let selectedProducts = {...this.props.selectedProducts};
            let cutProducts = {...this.props.cutProducts};
            for(let productIndex in selectedProducts) {
                let selectedProduct = selectedProducts[productIndex];
                if(selectedProduct.id in cutProducts) {
                    productsLengths[selectedProduct.id] = [{none: {...LENGTH_INFORMATION, isFirst: true}}];
                }
            }
            this.setState({
                productsLengths: productsLengths
            })
        }
        if(this.props.productsToCartLengths && Object.keys(this.props.productsToCartLengths).length > 0) {
            let tempProductsLength = {...this.props.productsToCartLengths};

            this.setState({
                productsLengths: {...tempProductsLength}
            })
        }
        this.initializeShipping();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.productsLengths != this.state.productsLengths) {
            if(this.timeOut){
                clearTimeout(this.timeOut)
            }
            this.timeOut = setTimeout(() => {
                let totalPrice = this.getTotalPrice();
                this.props.setOrderProcessTotalPrice(totalPrice);
            }, 500);
        }
        if(prevProps.productsToCartShipping != this.props.productsToCartShipping) {
            if(this.timeOut){
                clearTimeout(this.timeOut)
            }
            this.timeOut = setTimeout(() => {
                let totalPrice = this.getTotalPrice();
                this.props.setOrderProcessTotalPrice(totalPrice);
            }, 500);
        }
        if(prevProps.productsToCartCustomOptions != this.props.productsToCartCustomOptions) {
            if(this.timeOut){
                clearTimeout(this.timeOut)
            }
            this.timeOut = setTimeout(() => {
                let totalPrice = this.getTotalPrice();
                this.props.setOrderProcessTotalPrice(totalPrice);
            }, 500);
        }
        if(prevProps.productsToCartLengths != this.props.productsToCartLengths) {
            console.log('entering productsto cart', prevProps.productsToCartLengths);
            console.log('entering productsto cart', this.props.productsToCartLengths);
            this.setState({
                productsLengths: this.props.productsToCartLengths
            })
        }
        if(prevProps.selectedProducts != this.props.selectedProducts) {
            let selectedProducts = this.props.selectedProducts;
            let shippingProducts = {...this.props.productsToCartShipping};
            for(let shippingIndex in shippingProducts) {
                if(!selectedProducts[shippingIndex]) {
                    delete shippingProducts[shippingIndex];
                }
            }
            this.props.setShippingProducts(shippingProducts)
        }
    }

    initializeShipping() {
        let productsToCartShipping = this.props.productsToCartShipping;
        let selectedProducts = this.props.selectedProducts;
        if(!productsToCartShipping || Object.keys(productsToCartShipping).length == 0) {
            productsToCartShipping = {};
            Object.keys(selectedProducts).map((value, index, array) => {
                let selectedProduct = selectedProducts[value];
                if (selectedProduct.extension_attributes.shipping) {
                    productsToCartShipping[selectedProduct.id]= [null]
                }
            })
        }
        else {
            Object.keys(selectedProducts).map((value, index, array) => {
                let selectedProduct = selectedProducts[value];
                if(!productsToCartShipping[value]) {
                    if (selectedProduct.extension_attributes.shipping) {
                        productsToCartShipping[selectedProduct.id] = [null];
                    }
                }
            })
        }
        console.log('initializeShipping', productsToCartShipping)
        this.props.setShippingProducts(productsToCartShipping);
    }

    saveProductInfoState(product, lengths)
    {
        console.log('saveProductInfoState Product:', product.sku);
        console.log('this.state.productsLengths', this.state.productsLengths);
        let productsLengths = {...this.state.productsLengths};

        productsLengths[product.id] = lengths;
        console.log('productsLengths', productsLengths);
        this.setState({
            productsLengths: productsLengths
        }, () => {
                this.props.setProductsLengths({...this.state.productsLengths})
            console.log('after save state', this.state.productsLengths)}
            )
    }
    goBack()
    {
        this.saveLengthsInfo();
        this.props.goBack();
    }
    saveLengthsInfo()
    {
        this.props.setProductsLengths({...this.state.productsLengths})
    }

    getTotalPrice() {
        let {productsToCartSharedDetails,
            productsToCartShows, selectedProducts} = {...this.props};
        let productsToCartCustomOptions  = this.props.productsToCartCustomOptions;

        let optionsProduct = {};
        let selectedProductsTemp = {};
        for (let spIndex in selectedProducts) {
            let options = [productsToCartSharedDetails];
            let selectedProduct = selectedProducts[spIndex];

            if(
                selectedProduct.extension_attributes && selectedProduct.extension_attributes.product_type
                && selectedProduct.extension_attributes.product_type.id !== 'shipping'

            ){
                selectedProductsTemp = {
                    ...selectedProductsTemp,
                    [spIndex]: selectedProduct
                }
                let productSku = selectedProduct.sku;
                if(productsToCartCustomOptions && productSku in productsToCartCustomOptions) {
                    options = options.concat([productsToCartCustomOptions[productSku]]);
                    console.log('options productSku', [options, productSku, productsToCartCustomOptions])
                }
                optionsProduct[productSku] = options
            }
        }
        console.log('alloptions', optionsProduct);
        let totalPrice = getTotalPrice(selectedProductsTemp, optionsProduct, productsToCartShows);
        let shippingTotal = this.getProductsShippingPrice();
        let cutProductsTotal = this.getProductsCutPrice();
        let artCustomPrice = this.getArtProductCustomPrice();
        //
        console.log('totalPrice', totalPrice)
        console.log('artCustomPrice', artCustomPrice)
        totalPrice = parseFloat(totalPrice) + parseFloat(shippingTotal) + parseFloat(cutProductsTotal) + artCustomPrice;
        console.log('shippingTotal', shippingTotal)

        return totalPrice;
    }
    _getArtProductConfigSelected() {
        let productsToCartCustomOptions = this.props.productsToCartCustomOptions;
        let configSelected = [];
        if(productsToCartCustomOptions){
            for(let index in productsToCartCustomOptions) {
                let customOptions = productsToCartCustomOptions[index];
                if("art_product_config_id" in customOptions) {
                    configSelected.push(customOptions["art_product_config_id"]);
                }
            }
        }
        return configSelected;
    }
    _getArtProductCustomPriceById(configId) {
        let categoryInfo = this.props.categoryInfo;
        let customPrice = 0;
        if(categoryInfo && categoryInfo.art_product_config) {
            for (let index in categoryInfo.art_product_config) {
                let config = categoryInfo.art_product_config[index];
                if(config.id.toString() === configId.toString()) {
                    customPrice = config.custom_price;
                }
            }
        }
        return parseFloat(customPrice);
    }
    getArtProductCustomPrice() {

        let artProductsConfigSelected = this._getArtProductConfigSelected();
        let price = 0.0;
        for(let index in artProductsConfigSelected) {
            let productConfigSelected = artProductsConfigSelected[index];
            price += this._getArtProductCustomPriceById(productConfigSelected);
        }

        let additionalPkgProducts = this.props.additionalPkgProducts;
        if(additionalPkgProducts){
          for(let productSku in additionalPkgProducts) {
              for (let productId in additionalPkgProducts[productSku]){
                price += this._getArtProductCustomPriceById(productId);
              }
          }
        }
        return price;
    }
    /**
     * Get total price of cut products.
     * @returns {number}
     */
    getProductsCutPrice() {
        let productsLengths  = this.state.productsLengths;
        let allCutProducts = this.props.cutProducts;
        let selectedProducts = this.props.selectedProducts;

        let count = 0;
        let total = 0.00;
        for(let cutProductId in productsLengths) {
            let productLength = productsLengths[cutProductId];
            let selectedProduct = selectedProducts[cutProductId];
            if(selectedProduct && allCutProducts && selectedProduct.id in allCutProducts) {
                let cutProducts = allCutProducts[selectedProduct.id]
                productLength = setCutTypeToAllCutLength(productLength, selectedProduct.sku);
                for (let lengthIndex in productLength) {
                    let lengthData = productLength[lengthIndex];

                    for (let dataIndex in lengthData) {
                        let data = lengthData[dataIndex];
                        if(cutProducts && data.cut_type in cutProducts) {
                            let cutProduct = cutProducts[data.cut_type];
                            console.log('productLength', cutProduct)
                            console.log('productLength', data)
                            total += getTotalPrice([cutProduct], {[cutProduct.sku]:[data]}, []);
                        }
                    }
                }
            }
            count = 0;
        }
        return total;
    }
    getProductsShippingPrice() {
        let productsToCartShipping  = this.props.productsToCartShipping;
        let selectedProducts  = this.props.selectedProducts;
        let shippingTotal = 0;
        let shippingProducts = {};

        for (let pIndex in selectedProducts) {
            let selectedProduct = selectedProducts[pIndex];
            let shippingProduct = selectedProduct.extension_attributes.shipping;
            if(selectedProduct.extension_attributes.product_type.id == 'shipping') {
                shippingProduct = selectedProduct;
            }
            shippingProducts[pIndex] = shippingProduct;
        }
        for(let pShippingIndex in productsToCartShipping) {
            let shippingOptionsArray = productsToCartShipping[pShippingIndex];
            for(let shippingOptionIndex in shippingOptionsArray) {
                let shippingOptions = shippingOptionsArray[shippingOptionIndex];
                console.log('shippingOptions', shippingOptions)
                let shippingTotalTemp = 0;
                if(shippingOptions){
                    let shippingProduct = shippingProducts[pShippingIndex];
                    if(shippingProduct){
                        shippingOptions = {
                            [shippingProduct.sku]: [shippingOptions]
                        }
                        console.log('shippingOptions 1', shippingOptions);
                        shippingTotalTemp = getTotalPrice([shippingProduct], shippingOptions, []);
                    }
                }
                console.log('shippingTotalTemp', shippingTotalTemp);
                shippingTotal += parseFloat(shippingTotalTemp)
            }
        }
        return shippingTotal;
    }

    getProductType(product) {
        let productType = '';
        product.custom_attributes.map((attr) => {
            if(attr.attribute_code === "product_type") {
                productType = attr.value;
            }
        })
        return productType
    }

    checkInputTypeEmail() {
        let inputs = document.getElementById('order-form-step3').getElementsByTagName('input');
        let emailValid = true;
        for(let inputIndex in inputs) {
            let input = inputs[inputIndex]
            console.log(input);
            if(input.type == 'email'){
                if(!validEmailRegex.test(input.value)) {
                    if(!input.classList.contains('error')){
                        input.classList.add('error')
                        emailValid = false;
                    }
                }
                else {
                    if(input.classList && input.classList.contains('error')){
                        input.classList.remove('error')
                    }
                }
            }

        }
        return emailValid;
    }
    checkSkuInProductsToCart(sku) {
        let selectedProducts = this.props.productsToCart;
        for(let pId in selectedProducts) {
            let product = selectedProducts[pId];
            console.log('selected product sku', sku)
            console.log('selected product sku', product.sku)
            if(product.sku == sku) {
                return true;
            }
        }
        return false;
    }
    productHasCuts(selectedProduct) {
        let cutProducts = this.props.cutProducts;
        if(cutProducts && selectedProduct && selectedProduct.id in  cutProducts) {
            return true
        }
        return false;
    }
    getProductLengthsErrors() {
        let errors = {}
        let productsLength = this.state.productsLengths;
        let selectedProducts = this.props.selectedProducts;

        for (let sPIndex in selectedProducts) {
            let selectedProduct = selectedProducts[sPIndex];

            if (this.productHasCuts(selectedProduct))
            {

                let productLengths = productsLength[selectedProduct.id];
                let lengthErrors = {};

                for (let lengthIndex in productLengths) {

                    let length = productLengths[lengthIndex];
                    let lengthValid = true;
                    let videoSizeValid = true;
                    Object.keys(length).map((lengthCut) => {
                        let lengthData = length[lengthCut];
                        if (lengthData.length === "") {
                            lengthValid = false;
                        }
                        if(selectedProduct.sku === 'Online Video' || selectedProduct.sku === 'Theatrical Online Video') {
                            if(!lengthData.video_size) {
                                videoSizeValid = false;
                            }
                        }
                    })
                    if (!lengthValid) {
                        lengthErrors[lengthIndex] = [FIELDS_CODE.length.code]
                    }
                    if(!videoSizeValid) {
                        if (lengthIndex in lengthErrors) {
                            lengthErrors[lengthIndex].push(FIELDS_CODE.video_size.code);
                        } else {
                            lengthErrors[lengthIndex] = [FIELDS_CODE.video_size.code]
                        }
                    }
                    let cutsValid = true;
                    if ('none' in length) {
                        cutsValid = false;
                    }
                    if (!cutsValid) {
                        if (lengthIndex in lengthErrors) {
                            lengthErrors[lengthIndex].push(FIELDS_CODE.cuts.code);
                        } else {
                            lengthErrors[lengthIndex] = [FIELDS_CODE.cuts.code]
                        }
                    }
                }

                if (Object.keys(lengthErrors).length !== 0) {
                    errors[selectedProduct.sku] = lengthErrors;
                }
            }
        }
        return errors;
    }
    formValid() {
        let errors = {'lengthInformation': {}}
        errors.lengthInformation = this.getProductLengthsErrors();

        console.log('errors', errors);
        let customOptionsErrors = this.getAllCustomOptionErrors();
        errors = {
            ...errors,
            ...customOptionsErrors,
        }
        for(let errorsIndex in errors) {
            if(!errors[errorsIndex] || Object.keys(errors[errorsIndex]).length === 0) {
                delete errors[errorsIndex];
            }
        }
        let state = {...this.state};
        state['formErrors'] = errors;
        this.setState({...state});
        return errors;
    }

    getRequiredFields(product) {
        let options = product.options;
        let requiredFields = {};
        for(let optionIndex in options) {
            let option = options[optionIndex];
            if(option?.extension_attributes?.option_code === 'category_id') {
                continue;
            }
            if(option.is_require) {
                let extAttributes = option.extension_attributes;
                requiredFields[extAttributes.option_code] = {type: option.type};
            }
        }
        return requiredFields;
    }
    getAllCustomOptionErrors() {
        let products = this.props.selectedProducts;
        let productsToCartCustomOptions = this.props.productsToCartCustomOptions;
        let productsToCartShipping = this.props.productsToCartShipping;
        let customOptionErrors = {};
        let shippingErrors = {};
        for(let productIndex in products) {
            let product = products[productIndex];
            if(this.checkSkuInProductsToCart(product.sku)) {
                let customOptions = null;
                if(productsToCartCustomOptions) {
                    customOptions = productsToCartCustomOptions[product.sku];
                }
                let error = this.getFormErrors(customOptions, product);
                if(error.length > 0){
                    customOptionErrors[product.sku] = error;
                }
            }
            if(productsToCartShipping) {
                let shippingProduct = product.extension_attributes.shipping;
                let shippingOptionsArray = productsToCartShipping[product.id];
                console.log("shippingOptionsArray", shippingOptionsArray);
                console.log("shippingProduct", shippingProduct);
                if(shippingProduct) {
                    for(let shippingOptionIndex in shippingOptionsArray) {
                        let shippingOption = shippingOptionsArray[shippingOptionIndex];
                        let shippingError = this.getFormErrors(shippingOption, shippingProduct);
                        let requiredDependent = this.getDependentRequired(shippingOption, shippingProduct);
                        console.log('requiredDependent', requiredDependent)
                        let errors = [...shippingError, ...requiredDependent];
                        if(errors.length > 0) {
                            if(product.id in shippingErrors){
                                shippingErrors[product.id][shippingOptionIndex] = errors;
                            }
                            else {
                                shippingErrors[product.id] = {
                                    [shippingOptionIndex]: errors
                                }
                            }
                        }
                    }
                }
            }
        }
        let validEmailInput = this.checkInputTypeEmail();
        if(!validEmailInput) {
            shippingErrors['general'] = ['email_error'];
        }
        console.log("shippingErrors", shippingErrors)
        return {'shipping': shippingErrors, customOptions: customOptionErrors};
    }
    getFormErrors(productCustomOptions, product)
    {
        let requiredFields = this.getRequiredFields(product);
        console.log("requiredFields", requiredFields);
        let customOptions = productCustomOptions;
        let formErrors = [];
        Object.keys(requiredFields).map((value) => {
            let requiredField = requiredFields[value];
            if(customOptions == null) {
                formErrors.push(value);
            }
            else
            {
                if(!customOptions[value]) {
                    formErrors.push(value)
                }
                else {
                    let customOptionValid = false;
                    if(requiredField.type == 'checkbox') {

                        for(let cOptionIndex in customOptions) {
                            let customOption = customOptions[cOptionIndex];
                            if(customOption) {
                                customOptionValid = true
                            }
                        }
                    }
                    else {
                        if(customOptions[value] != '') {
                            customOptionValid = true;
                        }
                    }
                    if(!customOptionValid) {
                        formErrors.push(value);
                    }
                }

            }

        });
        let requiredDependent = this.getDependentRequired(productCustomOptions, product);
        formErrors = [...formErrors, ...requiredDependent];
        console.log('Custom formErrors', formErrors);
        console.log('Custom requiredFields', requiredFields);
        return formErrors;
    }
    getDependentRequired(productCustomOptions, product) {
        let options = product.options;
        let requiredFields = [];
        if(!productCustomOptions) {
            return [];
        }

        for(let optionIndex in options) {
            let option = options[optionIndex];
            console.log('option', option);
            let mainOptionCode = option.extension_attributes.option_code;
            if(mainOptionCode in productCustomOptions && productCustomOptions[mainOptionCode] && productCustomOptions[mainOptionCode] != '') {
                continue;
            }
            console.log('required_dependent', option.extension_attributes.required_dependent)
            if(option.extension_attributes && option.extension_attributes.required_dependent) {
                let required_dependent = option.extension_attributes.required_dependent;
                required_dependent = required_dependent.split('|')
                let code = required_dependent[0];
                let sku = undefined;
                if(required_dependent.length === 2) {
                    sku = required_dependent[1]
                }
                let requiredOption = getOptionByCode(options, code);
                if(requiredOption) {
                    if(requiredOption.type === 'checkbox') {
                        if(code in productCustomOptions) {
                            if(productCustomOptions[code] && sku in productCustomOptions[code] && productCustomOptions[code][sku]){
                                requiredFields.push(option.extension_attributes.option_code)
                            }
                        }
                    }
                    if(requiredOption.type == 'drop_down' || requiredOption.type == 'radio') {
                        if(code in productCustomOptions) {
                            if(sku == productCustomOptions[code]) {
                                requiredFields.push(option.extension_attributes.option_code)
                            }
                        }
                    }
                }
            }
        }
        return requiredFields;
    }
    showOnlyShippingForm() {
        let selectedProducts = this.props.selectedProducts;
        let showOnlyShipping = true;
        if(selectedProducts) {
            for(let index in selectedProducts) {
                let selectedProduct = selectedProducts[index];
                if(selectedProduct.extension_attributes && selectedProduct.extension_attributes.product_type
                && selectedProduct.extension_attributes.product_type.id != 'shipping')
                {
                    showOnlyShipping = false;
                }
            }
        }
        else {
            showOnlyShipping = false
        }
        return showOnlyShipping;
    }
    submitForm(event) {
        event.preventDefault();
        let button = document.getElementById('nextStepButton')
        if(button) {
            button.click();
        }
    }
    render() {
        let {isFetchingAddProductToCart,
            productsToCart, productsToCartShows, customerInfo,
            selectedProducts, productsToCartSharedDetails, orderTotalPrice,
            productsToCartCustomOptions,
            categoryId, itemsToDelete, cart, comeFrom, cutProducts} = {...this.props};

        let productsToCartShipping = {...this.props.productsToCartShipping};
        let productsLengths = {...this.state.productsLengths};
        let categoryInfo = {...this.state.categoryInfo};
        let formErrors = {...this.state.formErrors};

        if(isFetchingAddProductToCart) {
            return (
                <LoadingSmall
                    label="Updating cart"
                />
            )
        }
        else {
            let addProductsToCartError = this.props.addProductsToCartError;
            return (
                <form onSubmit={this.submitForm} id={"order-form-step3"}>
                    {(Object.keys(formErrors).length > 0 || addProductsToCartError) && <div className="alert error">
                        {Object.keys(formErrors).length > 0 && <p>Please complete the fields outlined in red below before proceeding.</p>}
                        {addProductsToCartError && <p>{addProductsToCartError}</p>}
                    </div>}
                    <small className="required mb-20">Required</small>

                        {comeFrom != 'addShipping' && selectedProducts &&
                        Object.keys(selectedProducts).map((value, index) =>{
                            let selectedProduct = selectedProducts[value];
                            if(selectedProduct.extension_attributes && selectedProduct.extension_attributes.product_type
                                && selectedProduct.extension_attributes.product_type.id != 'shipping'
                            )
                                {
                                    return <OrderFormStep3ProductInfo
                                        product={{...selectedProducts[value]}}
                                        productsLengths={{...productsLengths}}
                                        productCustomOptions={productsToCartCustomOptions}
                                        saveLengthsInfo={this.saveLengthsInfo}
                                        key={`product-`+ value}
                                        saveProductInfoState={this.saveProductInfoState}
                                        index={index}
                                        formErrors={formErrors}
                                        categoryInfo={categoryInfo}
                                        productsToCartShows={productsToCartShows}
                                        itemsToDelete={itemsToDelete}
                                        shippingProducts={productsToCartShipping}
                                        customerEmail={customerInfo ? customerInfo.email: ''}
                                        customerId={customerInfo ? customerInfo.id: ''}
                                        selectedProducts={selectedProducts}

                                        cutProducts={cutProducts}

                                    />
                                }
                            }
                        )}
                        {selectedProducts && customerInfo && this.showOnlyShippingForm() && <OrderFormStep3Shipping
                            selectedProducts={selectedProducts}
                            shippingProducts={productsToCartShipping}
                            customerId={customerInfo.id}
                            customerEmail={customerInfo.email}
                            itemsToDelete={itemsToDelete}
                            formErrors={formErrors}
                        />}
                    <div id="order-total" className="order-total">
                        <dl>
                            <dt>Order Estimate:</dt>
                            <dd>{numberFormat(orderTotalPrice)}</dd>
                        </dl>
                    </div>

                    <div className="order-options" id="order-options-bottom">
                        <div className="row">
                            <div className="col-sm-3">
                                <button type="button" className="button medium white-white" onClick={this.goBack}>Back</button>
                            </div>
                            <UpdateCartItemProduct
                                productsToCart={productsToCart}
                                productsToCartShows={productsToCartShows}
                                productsToCartSharedDetails={productsToCartSharedDetails}
                                productsToCartCustomOptions={productsToCartCustomOptions}
                                productsLengths={productsLengths}
                                selectedProducts={selectedProducts}
                                customerInfo={customerInfo}
                                saveLengthsInfo={this.saveLengthsInfo}
                                categoryId={categoryId}
                                categoryInfo={categoryInfo}
                                productsToCartShipping={productsToCartShipping}
                                itemsToDelete={itemsToDelete}
                                cartInfo = {cart}
                                comeFrom={this.props.comeFrom}
                                itemId={this.props.itemId}
                                isValidForm={this.formValid}

                                cutProducts={cutProducts}
                            />
                        </div>
                    </div>

                </form>
            )
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderFormStep3General))
